@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;800&display=swap");

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: gray;
	padding: 0;
	margin: 0;
}

::-webkit-scrollbar-track {
	border-radius: 5px;
	background: #f5f5f5;
	padding: 0;
}

html {
	font-size: 18px;
}

#SvgjsText1041 {
	display: none !important;
}

.sidebar-transition {
	transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#SvgjsCircle1038 {
	position: relative !important;
	top: 50% !important;
}

body {
	font-size: 15px;
	font-weight: 300;
	font-family: Montserrat, Helvetica, sans-serif;
}

.container {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.raphael-group-13-caption text {
	fill: #79a1de !important;
	letter-spacing: 0.9px;
}

#chartobject-1 svg .raphael-group-3-parentgroup ~ g text {
	display: none !important;
}

.absolute-center {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.cusWhi {
	background: rgba(255, 255, 255, 0.2);
}
.cusCalSha {
	box-shadow: 0 0 7px 10px rgba(0, 0, 0, 0.02);
}

.cusBlue {
	background-color: #14266f;
}

.admin-blue-text {
	color: #4662a6;
}
.admin-blue-bg {
	background-color: #4662a6;
}

.check-hover-loan:focus {
	border: 2px solid #223564;
}

.radio-btn-custom {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.btn-border-blue {
	border: 2px solid #223564;
}

.sidebar-bg {
	/* background: linear-gradient(180deg, #274cdb 0%, #14266e 100%) 0 0 no-repeat; */
	/* background: linear-gradient(180deg, #223564 0%, #14266e 100%) 0 0 no-repeat; */
	background: #223564;
}
.sidebar-bg2 {
	/* background: linear-gradient(180deg, #274cdb 0%, #14266e 100%) 0 0 no-repeat; */
	background: #14266f 0% 0% no-repeat padding-box;
}
.invert-1 {
	filter: invert(1);
}

@keyframes spin {
	0% {
		transform: rotateZ(0deg);
	}

	100% {
		transform: rotateZ(360deg);
	}
}

.spinner-spin {
	height: 100%;
	min-height: 10px;
	width: 100%;
	min-width: 10px;
	border: 4px solid #f5f5f5;
	border-top: 4px solid black;
	background: transparent;
	animation: spin 400ms linear infinite;
	display: block;
	margin: 0 auto;
	border-radius: 50%;
}

.custom-table2 {
	border-spacing: 0 8px;
	border-collapse: separate;
	margin: 10px auto 0px;
	width: 100%;
	/* min-width: 100%; */
}

.custom-table2 td,
.custom-table2 th {
	/* background-color: white; */

	border: 0;
	border-bottom: 0.2rem solid transparent;
	padding: 1rem 1.5rem;
	font-size: 12px;
	/*white-space: nowrap;*/
	/* font-size: 0.75rem; */
}

.custom-table2 th {
	border-bottom: 0.5rem solid transparent;
	text-align: left;
	/* font-size: 0.85rem; */
	background: #e7f6ff;
	font-size: 12px;
	font-weight: normal;
	white-space: nowrap;
}

.custom-table2 tr {
	background-color: white;
}

.custom-table2 tr td {
	box-shadow: 0 2px 0px rgba(0, 0, 0, 0.02);
}

.custom-table2 tr td:first-child {
	border-bottom-left-radius: 0.7rem;
	border-top-left-radius: 0.7rem;
}

.custom-table2 tr th:first-child {
	border-bottom-left-radius: 0.7rem;
	border-top-left-radius: 0.7rem;
}

.custom-table2 tr td:last-child {
	border-bottom-right-radius: 0.7rem;
	border-top-right-radius: 0.7rem;
}

.custom-table2 tr th:last-child {
	border-bottom-right-radius: 0.7rem;
	border-top-right-radius: 0.7rem;
}

/* .custom-table2 {
	border-spacing: 0 10px;
	border-collapse: separate;
	margin: 0px auto 0px;
	width: 100%;
}

.custom-table2 td,
.custom-table2 th {
	background-color: white;
	border: 0;
	border-bottom: 0.2rem solid transparent;
	border-bottom: 2px solid #f7fcfa;
	padding: 1rem 1.5rem;
	white-space: nowrap;
	font-size: 0.75rem;
}

.custom-table2 th {

	text-align: left;
	font-size: 0.85rem;
	white-space: nowrap;
}
.custom-table2 tr td:last-child {
		border-bottom-right-radius: 0.7rem;
		border-top-right-radius: 0.7rem;
	}

.custom-table2 tr {
	background-color: white;
}
.custom-table2 tbody tr:hover td {
	background-color: #f4f7f9;
}

.custom-table2 tr td:first-child {
	border-bottom-left-radius: 0.7rem;
	border-top-left-radius: 0.7rem;
}

.custom-table2 tr th:first-child {
	border-bottom-left-radius: 0.7rem;
	border-top-left-radius: 0.7rem;
}

.custom-table2 tr td:last-child {
	border-bottom-right-radius: 0.7rem;
	border-top-right-radius: 0.7rem;
}

.custom-table2 tr th:last-child {
	border-bottom-right-radius: 0.7rem;
	border-top-right-radius: 0.7rem;
} */

.table-curves-left {
	border-bottom-right-radius: 0.7rem;
	border-top-right-radius: 0.7rem;
}
.sidebar-bg-gradient {
	/* background: linear-gradient(180deg, #279ddb 0%, #14366e 100%) 0 0 no-repeat; */
	/* background-color: #279ddb; */
	background-color: #eaeef3;
}
.table-container-shadow {
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}

.w-screen {
	min-width: 830px;
}

/* * {
    border: 1px solid rebeccapurple !important;
} */
.text-small {
	font-size: 0.5rem;
}

.text-small2 {
	font-size: 0.6rem;
}

.apexcharts-legend-series {
	margin: 5px 5px !important;
	background: white;
	box-shadow: 3px 3px 5px 0px lightgray;
	padding: 10px 20px;
	border-radius: 15px;
	color: #1a202c;
}

.drop {
	transition-property: opacity, transform, margin, max-height;
	transition-timing-function: ease-out;
	max-height: 0%;
	transform: scaleY(0);
	transform-origin: top;
	opacity: 0;
	overflow-y: hidden;
}

.drop-in {
	max-height: 80px;
	opacity: 1;
	transform: scaleY(1);
	transition-duration: 200ms;
}
.drop.drop-out {
	max-height: 0;
	opacity: 0;
	transform: scaleY(0);
	transition-duration: 200ms;
}

.s-drop {
	transition-property: opacity, transform, margin, max-height;
	transition-timing-function: ease-out;
	max-height: 0;
	transform: scaleY(0);
	transform-origin: top;
	opacity: 0;
	overflow-y: hidden;
}

.s-drop-in {
	max-height: 550px;
	opacity: 1;
	transform: scaleY(1);
	transition-duration: 600ms;
}
.s-drop-out {
	max-height: 0;
	opacity: 0;
	transform: scaleY(0);
	transition-duration: 400ms;
}
.fade-enter {
	opacity: 0;
}

.fade-enter {
	opacity: 0.01;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	transition: 700ms;
}

.fade-exit {
	opacity: 1;
}

.fade-exit.fade-exit-active {
	opacity: 0.01;
	transition: 500ms;
}

.animate-slide-down {
	animation-name: slide-down;
	animation-duration: 1s;
	transform: scaleY(1);
	transform-origin: top;
}

@keyframes slide-down {
	0% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
	}
}

.small-width {
	width: 1px;
	height: 10px;
}
.small-font {
	font-size: 6px;
}
.small-font3 {
	font-size: 12px;
}
.small-font2 {
	font-size: 12px;
}
.cusCol {
	color: #748aa1;
}
.dashCusGrid {
	grid-template-columns:
		80px 100px
		minmax(150px, 1.2fr) minmax(90px, 1fr) minmax(90px, 1fr) minmax(90px, 1fr)
		minmax(90px, 1fr) minmax(90px, 1fr) minmax(90px, 1fr) minmax(90px, 1fr)
		50px;
}

/* 80px 100px 100px 100px 100px 100px 100px 100px 100px 100px 50px; */

.hrr-center {
	top: 3px;
	right: 50%;
	transform: translateX(-50%);
}
.hrr-center2 {
	top: 20px;
	text-align: center;
}
.force-black {
	color: #000;
}
.force-black-bg {
	background-color: #000;
}

.lc-btn {
	background-color: #66eacf;
}
.force-transparent {
	background-color: transparent !important;
}
.force-gray {
	background-color: #e7f6ff !important;
	/* background-color: #cbd5e0 !important; */
}

.cusTabWid {
	min-width: 1000px;
}
.buttton-green {
	background-color: #68d391 !important;
}
.twenty-two {
	flex-basis: 22%;
}
.cusBtn {
	background-color: #4b49ae;
}
.force-cell-width {
	width: 500px !important;
}

.refactored-table {
	/* width: 70%; */
	margin-top: 40px;
	border-spacing: 0 8px;
	border-collapse: separate;
}

/* @media (max-width: 1023px) {
	.refactored-table {
		width: 900px;
	}
} */

.refactored-table tr th {
	/* background: transparent; */
	background: #e7f6ff;
	/* background: #c6f6d5; */
	padding-top: 15px;
	padding-bottom: 15px;
	text-align: center;
	/* padding-bottom: 35px; */
}

.refactored-table tr th:first-child {
	/* padding-left: 40px; */
	text-align: left;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.refactored-table tr th:last-child {
	text-align: left;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 2px solid transparent;
}

.refactored-table tr {
	background-color: #ffffff;
	border: 0;
	position: relative;
}

.refactored-table tr:hover {
	background-color: #e9eaeb;
}

.refactored-table thead tr:hover {
	background-color: transparent;
}
.refactored-table thead tr {
	background-color: transparent;
}

.refactored-table tr td {
	border-top: 2px solid transparent;
	text-align: center;
	border-bottom: 2px solid transparent;
	padding-top: 10px;
	padding-bottom: 10px;
	box-shadow: 0 2px 0px rgba(0, 0, 0, 0.02);
}

.refactored-table tr td:first-child {
	/* border: 2px solid #f0f0f0; */
	border-right: none;
	padding-left: 20px;
	text-align: left;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.refactored-table tr td:last-child {
	border: 2px solid transparent;
	border-left: none;
	text-align: left;
	position: relative;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	padding-left: 10px;
}

.tb-100 {
	width: 100%;
}
.tb-70 {
	width: 70%;
}
.tb-27 {
	width: 27%;
}

.cus-rep-table {
	/* width: 70%; */
	margin-top: 40px;
	border-spacing: 0 20px;
	border-collapse: separate;
}

.cus-rep-table tr th {
	background: transparent;
	/* background: #c6f6d5; */
	padding-top: 0px;
	padding-bottom: 0px;
	text-align: center;
	/* padding-bottom: 35px; */
}

.cus-rep-table tr th:first-child {
	/* padding-left: 40px; */
	text-align: left;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.cus-rep-table tr th:last-child {
	text-align: left;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 2px solid transparent;
}

.cus-rep-table tr {
	background-color: #ffffff;
	border: 0;
	position: relative;
}

/* .cus-rep-table tr:hover {
	background-color: #e9eaeb;
} */

.cus-rep-table thead tr:hover {
	background-color: transparent;
}
.cus-rep-table thead tr {
	background-color: transparent;
}

.cus-rep-table tr td {
	border-top: 2px solid transparent;
	text-align: center;
	border-bottom: 2px solid transparent;
	padding-top: 20px;
	padding-bottom: 20px;
	box-shadow: 0 2px 0px rgba(0, 0, 0, 0.05);
}

.cus-rep-table tr td:first-child {
	/* border: 2px solid #f0f0f0; */
	border-right: none;
	padding-left: 20px;
	text-align: left;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.cus-rep-table tr td:last-child {
	border: 2px solid transparent;
	border-left: none;
	text-align: left;
	position: relative;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	padding-left: 10px;
}

.refactored-table-dash {
	/* width: 70%; */
	margin-top: 40px;
	border-spacing: 0 8px;
	border-collapse: separate;
}

.refactored-table-dash tr th {
	/* background: transparent; */
	/* background: #e7f6ff; */
	/* background: #c6f6d5; */
	padding-top: 15px;
	padding-bottom: 15px;
	text-align: center;
	/* padding-bottom: 35px; */
}

.refactored-table-dash tr th:first-child {
	/* padding-left: 40px; */
	text-align: left;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.refactored-table-dash tr th:last-child {
	text-align: left;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 2px solid transparent;
}

.refactored-table-dash tr {
	/* background-color: #ffffff; */
	border: 0;
	position: relative;
}

/* .refactored-table-dash tr:hover {
	background-color: #e9eaeb;
} */

.refactored-table-dash thead tr:hover {
	background-color: transparent;
}
.refactored-table-dash thead tr {
	background-color: transparent;
}

.refactored-table-dash tr td {
	border-top: 2px solid transparent;
	text-align: center;
	border-bottom: 2px solid transparent;
	padding-top: 10px;
	padding-bottom: 10px;
	/* box-shadow: 0 2px 0px rgba(0, 0, 0, 0.02); */
}

.refactored-table-dash tr td:first-child {
	/* border: 2px solid #f0f0f0; */
	border-right: none;
	padding-left: 20px;
	text-align: left;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.refactored-table-dash tr td:last-child {
	border: 2px solid transparent;
	border-left: none;
	text-align: left;
	position: relative;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	padding-left: 10px;
}

.tr-hover:hover {
	background-color: #fff !important;
}
